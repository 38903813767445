import { FC } from "react";

import { Icon, IconProps } from "@chakra-ui/icons";

const CustomIcon: FC<Omit<IconProps, "css">> = ({ children, ...props }) => (
  <Icon height="27" viewBox="0 0 20 27" fill="none" {...props}>
    <path
      d="M5.83933 15.9806H5.83733C5.61657 15.9806 5.40482 16.0786 5.24849 16.2532C5.09216 16.4278 5.00402 16.6646 5.00338 16.9119L4.98828 23.5091C4.98901 24.252 5.25283 24.9642 5.72187 25.4895C6.1909 26.0148 6.82684 26.3102 7.49015 26.3111H17.4976C18.161 26.3102 18.7969 26.0148 19.2659 25.4895C19.735 24.9642 19.9988 24.252 19.9995 23.5091V2.96117C19.9988 2.21828 19.735 1.50606 19.2659 0.980764C18.7969 0.455466 18.161 0.159996 17.4976 0.15918H7.49015C6.82645 0.160145 6.19021 0.456048 5.72113 0.981919C5.25205 1.50779 4.98848 2.22065 4.98828 2.96397L5.00338 9.44534C5.00402 9.69258 5.09216 9.92945 5.24849 10.104C5.40482 10.2786 5.61657 10.3766 5.83733 10.3766H5.83933C5.94889 10.3763 6.05731 10.3519 6.15842 10.3046C6.25953 10.2574 6.35133 10.1883 6.42859 10.1013C6.50585 10.0143 6.56705 9.91114 6.6087 9.79766C6.65034 9.68417 6.67161 9.56261 6.67129 9.43992L6.6562 2.96117C6.65644 2.71354 6.74438 2.47613 6.90072 2.30103C7.05707 2.12593 7.26905 2.02744 7.49015 2.02717H17.4976C17.7188 2.02742 17.9307 2.1259 18.0871 2.301C18.2434 2.47611 18.3314 2.71353 18.3316 2.96117V23.5091C18.3314 23.7567 18.2435 23.9941 18.0871 24.1693C17.9308 24.3444 17.7188 24.4428 17.4976 24.4431H7.49015C7.26941 24.4431 7.05766 24.345 6.90134 24.1705C6.74501 23.9959 6.65686 23.7591 6.6562 23.5119L6.67129 16.9173C6.67161 16.7946 6.65034 16.673 6.6087 16.5596C6.56705 16.4461 6.50585 16.3429 6.42859 16.2559C6.35133 16.1689 6.25953 16.0998 6.15842 16.0526C6.05731 16.0054 5.94889 15.9809 5.83933 15.9806Z"
      fill="#808285"
    />
    <path
      d="M0 13.1785C0 13.4262 0.0878634 13.6638 0.244261 13.839C0.400658 14.0141 0.612779 14.1125 0.833958 14.1125H10.9656L8.65273 16.1849C8.56718 16.2615 8.49593 16.3562 8.44306 16.4636C8.39019 16.5711 8.35674 16.6891 8.3446 16.811C8.33247 16.9329 8.34189 17.0563 8.37234 17.1742C8.40279 17.292 8.45366 17.402 8.52205 17.4978C8.59044 17.5936 8.67502 17.6734 8.77095 17.7326C8.86687 17.7918 8.97228 17.8293 9.08113 17.8429C9.18999 17.8565 9.30017 17.8459 9.40539 17.8118C9.5106 17.7777 9.60879 17.7207 9.69435 17.6441L13.8641 13.9082C13.8797 13.8942 13.8866 13.8738 13.9011 13.8588C13.9565 13.8024 14.0047 13.7378 14.0445 13.6668C14.0625 13.6344 14.0787 13.6007 14.0929 13.5659C14.1066 13.5406 14.1191 13.5145 14.1304 13.4878C14.1435 13.4076 14.1566 13.3275 14.1698 13.2474C14.1773 13.1457 14.1698 13.0433 14.1476 12.9444C14.1458 12.9371 14.147 12.9296 14.1451 12.9223C14.116 12.8154 14.0699 12.7152 14.0091 12.6265C14.0026 12.6166 14.0014 12.6045 13.9945 12.5948C13.9809 12.5761 13.9611 12.5673 13.9464 12.5498C13.9213 12.5138 13.8938 12.4801 13.8641 12.4489L9.69435 8.71291C9.60879 8.63631 9.5106 8.57934 9.40539 8.54524C9.30017 8.51114 9.18999 8.50058 9.08113 8.51417C8.97228 8.52776 8.86687 8.56523 8.77095 8.62445C8.67502 8.68366 8.59044 8.76345 8.52205 8.85927C8.38392 9.05278 8.32009 9.29982 8.3446 9.54604C8.36911 9.79226 8.47995 10.0175 8.65273 10.1722L10.966 12.2445H0.833958C0.612779 12.2445 0.400658 12.3429 0.244261 12.5181C0.0878634 12.6933 0 12.9308 0 13.1785Z"
      fill="#DC0032"
    />
  </Icon>
);

export default CustomIcon;
